class ProductRecord {
  constructor(page='product_records') {
    this.page = page
    this.initDataTable()

    $(".dataTables_scrollHead").scroll(function(){
      $(".dataTables_scrollBody").scrollLeft($(".dataTables_scrollHead").scrollLeft());
    });

    $(document).on('click', '.manufacturing_and_sales_comments span, .manufacturing_comment span, .device_description span', (e) =>{
      const href = $(e.target).closest('tr').find('a.detail-link').attr('href')
      if (!!href) {
        window.open(href, '_blank')
      }
    })
  }

  defaultActionCell(path, iconName, className) {
    return '<td class="icon-actions"><a href="%PATH%" class="%CLASS_NAME%" target="_blank"><div class="fas %ICON_NAME%"></div></a></td>'
  }

  getCellData(data, type, row, column) {
    let nameClass = (row[`is_${column}_changed`]) ? 'text-danger' : ''
    if (!!row['is_new_product_record'] && data != null) {
      return '<span class=text-danger>'+data+'</span>'
    }
    if (data != null) {
      if (column == 'bom_name') {
        return `<span data-value=${row.bom_id} class=${nameClass} >`+data+'</span>'
      } else if (column == 'model_group_name') {
        return `<span data-value=${row.model_group_id} class=${nameClass}>`+data+'</span>'
      } else if (column == 'assemble_group_name') {
        return `<span data-value=${row.assemble_group_id} class=${nameClass}>`+data+'</span>'
      } else if (column == 'stf2_create_target') {
        return `<span data-value=${row.stf2_create_target} class=${nameClass}>`+data+'</span>'
      } else if (column == 'specifications_undecided') {
        return `<span data-value=${row.specifications_undecided} class=${nameClass}>`+data+'</span>'
      } else if (column == 'bw_fw_n') {
        return `<span data-value=${row.original_bw_fw_n} class=${nameClass}>`+data+'</span>'
      } else if (column == 'stf_create_target') {
        return `<span data-value=${row.stf_create_target} class=${nameClass}>`+data+'</span>'
      } else if (column == 'plan_confirmation_flag') {
        return `<span data-value=${row.original_plan_confirmation_flag} class=${nameClass}>`+data+'</span>'
      } else if (column == 'long_term_delivery_list_target') {
        return `<span data-value=${row.long_term_delivery_list_target} class=${nameClass}>`+data+'</span>'
      } else if (column == 'development') {
        return `<span data-value=${row.development} class=${nameClass}>`+data+'</span>'
      } else if (column == 'is_incharge') {
        return `<span data-value=${row.is_incharge} class=${nameClass}>`+data+'</span>'
      } else {
        return `<span class=${nameClass}>`+data+'</span>'
      }
    } else {
      return '<span></span>'
    }
  }

  initDataTable() {
    let self = this
    let productRecordColumns = $('#product_records').data('columns-available')

    if (this.page === 'product_records' || this.page === 'drafts' || this.page === 'product_record_suppliers') {
      productRecordColumns = productRecordColumns.map(item => {
        item['render'] = function(data, type, row) {
          return self.getCellData(data, type, row, item['data'])
        }

        if (item['data'] == 'tf' || item['data'] == 'product_control_action' || item['data'] == 'design_action' || item['data'] == 'assembly_action' || item['data'] == 'sales_action') {
          // https://redmine.sazae.com.au/issues/3501
          item['createdCell'] = function(cell, cellData, rowData, rowIndex, colIndex){
            $(cell).css('background', rowData['tf_background'])
          }
        }

        if (item['data'] == 'buy_action') {
          item['createdCell'] = function(cell, cellData, rowData, rowIndex, colIndex){
            $(cell).css('background', rowData['buy_action_background'])
          }
        }

        if (item['data'] == 'production_and_sales_adjustment_delivery_date') {
          item['createdCell'] = function(cell, cellData, rowData, rowIndex, colIndex){
            $(cell).css('background', rowData['production_and_sales_adjustment_delivery_date_background'])
          }
        }

        if (item['data'] == 'alert') {
          item['createdCell'] = function(cell, cellData, rowData, rowIndex, colIndex){
            if (!!cellData) {
              $(cell).addClass('product-record-alert')
            }
          }
        }

        if (item['data'] == 'specifications_undecided'|| item['data'] == 'long_term_delivery_list_target' || item['data'] == 'stf_create_target' || item['data'] == 'stf2_create_target' || item['data'] == 'change_after_order_flg' || item['data'] == 'development') {
          item['createdCell'] = function(cell, cellData, rowData, rowIndex, colIndex){
            if (!!cellData) {
              $(cell).addClass('text-center')
            }
          }
        }

        return item
      })
    }

    if (this.page === 'product_records') {
      let userCanReadHistory = $('#product_records').data('user-can-read-history')
      $('#product_records thead tr').clone(true).appendTo( '#product_records thead' );
      $('#product_records thead tr:eq(1) th:not(.header_search_disabled)').each( function (i) {
        var title = $(this).text();
        $(this).addClass('cloned')
        $(this).html( '<input type="text" placeholder="検索 '+title+'" />' );
      });

      let historyActionCell = {
        defaultContent: this.defaultActionCell(),
        createdCell: ( cell, cellData, rowData, rowIndex, colIndex ) => {
          cellData = cellData.replace('%PATH%', Routes.product_record_product_record_histories_path(rowData.id))
          cellData = cellData.replace('%ICON_NAME%', 'fa-history')
          cellData = cellData.replace('%CLASS_NAME%', 'history-link')
          $(cell).replaceWith(cellData)
        }
      }

      let detailActionCell = {
        defaultContent: this.defaultActionCell(),
        createdCell: ( cell, cellData, rowData, rowIndex, colIndex ) => {
          cellData = cellData.replace('%PATH%', Routes.product_record_path(rowData.id))
          cellData = cellData.replace('%ICON_NAME%', 'fa-info-circle')
          cellData = cellData.replace('%CLASS_NAME%', 'detail-link')
          $(cell).replaceWith(cellData)
        }
      }

      let editActionCell = {
        defaultContent: this.defaultActionCell(),
        createdCell: ( cell, cellData, rowData, rowIndex, colIndex ) => {
          cellData = cellData.replace('%PATH%', Routes.edit_product_record_path(rowData.id))
          cellData = cellData.replace('%ICON_NAME%', 'fa-edit')
          cellData = cellData.replace('%CLASS_NAME%', 'edit-link')
          $(cell).replaceWith(cellData)
        }
      }
      if(!!userCanReadHistory){
        productRecordColumns.push(historyActionCell)
      }
      productRecordColumns.push(detailActionCell)
      productRecordColumns.push(editActionCell)
      productRecordColumns.unshift(editActionCell)
    } else if (this.page === 'drafts') {
      let userCanReadHistory = $('#product_records').data('user-can-read-history')
      $('#product_records thead tr').clone(true).appendTo( '#product_records thead' );
      $('#product_records thead tr:eq(1) th:not(.header_search_disabled)').each( function (i) {
        var title = $(this).text();
        $(this).addClass('cloned')
        $(this).html( '<input type="text" placeholder="検索 '+title+'" />' );
      });

      let historyActionCell = {
        defaultContent: this.defaultActionCell(),
        createdCell: ( cell, cellData, rowData, rowIndex, colIndex ) => {
          cellData = cellData.replace('%PATH%', Routes.product_record_draft_product_record_draft_histories_path(rowData.id))
          cellData = cellData.replace('%ICON_NAME%', 'fa-history')
          cellData = cellData.replace('%CLASS_NAME%', 'history-link')
          $(cell).replaceWith(cellData)
        }
      }

      let detailActionCell = {
        defaultContent: this.defaultActionCell(),
        createdCell: ( cell, cellData, rowData, rowIndex, colIndex ) => {
          cellData = cellData.replace('%PATH%', Routes.product_record_draft_path(rowData.id))
          cellData = cellData.replace('%ICON_NAME%', 'fa-info-circle')
          cellData = cellData.replace('%CLASS_NAME%', 'detail-link')
          $(cell).replaceWith(cellData)
        }
      }

      let editActionCell = {
        defaultContent: this.defaultActionCell(),
        createdCell: ( cell, cellData, rowData, rowIndex, colIndex ) => {
          cellData = cellData.replace('%PATH%', Routes.edit_product_record_draft_path(rowData.id))
          cellData = cellData.replace('%ICON_NAME%', 'fa-edit')
          cellData = cellData.replace('%CLASS_NAME%', 'edit-link')
          $(cell).replaceWith(cellData)
        }
      }
      if(!!userCanReadHistory){
        productRecordColumns.push(historyActionCell)
      }
      productRecordColumns.push(detailActionCell)
      productRecordColumns.push(editActionCell)
      productRecordColumns.unshift(editActionCell)
    } else if (this.page === 'product_record_suppliers') {
      $('#product_records thead tr').clone(true).appendTo( '#product_records thead' );
      $('#product_records thead tr:eq(1) th:not(.header_search_disabled)').each( function (i) {
        var title = $(this).text();
        $(this).addClass('cloned')
        $(this).html( '<input type="text" placeholder="検索 '+title+'" />' );
      });
    } else {
      let whoDunnitCell = {
        defaultContent: '<td><a href="%PATH%">%ACTOR_FULL_NAME%</a></td>',
        createdCell: ( cell, cellData, rowData, rowIndex, colIndex ) => {
          cellData = cellData.replace('%PATH%', !!rowData.actor_id ? Routes.user_path(rowData.actor_id) : '#')
          cellData = cellData.replace('%ACTOR_FULL_NAME%', rowData.actor_full_name)
          $(cell).replaceWith(cellData)
        }
      }

      productRecordColumns.unshift(whoDunnitCell)
    }

    let table = $('#product_records').DataTable({
      orderCellsTop: true,
      scrollY: 750,
      scrollX: true,
      scrollCollapse: true,
      scroller: {
        displayBuffer: 2,
      },
      processing: true,
      columns: productRecordColumns,
      columnDefs: [],
      autoWidth: false,
      stateSave: true,
      stateSaveCallback: function(settings,data) {
        delete data.scroller;
        localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
      },
      stateLoadCallback: function(settings) {
        return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )
      },
      order: JSON.parse(localStorage.getItem('order')) || [],
      language: {
        "sEmptyTable":     "テーブルにデータがありません",
        "sInfo":           " _TOTAL_ 件中 _START_ から _END_ まで表示",
        "sInfoEmpty":      " 0 件中 0 から 0 まで表示",
        "sInfoFiltered":   "（全 _MAX_ 件より抽出）",
        "sInfoPostFix":    "",
        "sInfoThousands":  ",",
        "sLengthMenu":     "_MENU_ 件表示",
        "sLoadingRecords": "読み込み中...",
        "sProcessing":     "処理中...",
        "sSearch":         "検索:",
        "sZeroRecords":    "一致するレコードがありません",
        "oPaginate": {
          "sFirst":    "先頭",
          "sLast":     "最終",
          "sNext":     "次",
          "sPrevious": "前"
        },
        "oAria": {
          "sSortAscending":  ": 列を昇順に並べ替えるにはアクティブにする",
          "sSortDescending": ": 列を降順に並べ替えるにはアクティブにする"
        }
      },
      ajax: {
        url: this.dataTableURL(),
        dataType: 'JSON',
        method: 'GET',
        dataSrc: function(response) {
          return response
        }
      },
      createdRow: ( row, data, dataIndex ) => {
        $(row).addClass( 'product-record-row' )
        $(row).data( 'recordId', data.id )
        const isEditable = data["is_editable"] != undefined ? data["is_editable"] : true
        $(row).attr("is-editable", isEditable)
      },
      initComplete: function () {
        // Apply the search
        function debounce(func, wait, immediate) {
          var timeout;
          return function() {
            var context = this, args = arguments;
            var later = function() {
              timeout = null;
              if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
          };
        };

        this.api().columns().every( function (index) {
          let that = this;
          let className = $(this.header()).data('class')
          let searchedKeyWords = JSON.parse(localStorage.getItem('searchedKeyWords')) || {}

          $('input', `.${className}.cloned`).on( 'keyup change clear', debounce(function() {
            if ( that.column(index).search() !== this.value ) {
              searchedKeyWords = Object.assign(searchedKeyWords, { [`keyword_at_${index}`]: this.value })
              localStorage.setItem('searchedKeyWords', JSON.stringify(searchedKeyWords))

              let keyWords = this.value.replace(/[.+*$^{\}()|[\]\\]/g, '\\$&').split(',')
              keyWords = keyWords.map(val => val.trim()).filter((value) => !!value).join('|')
              let words = null
              let key_begin = keyWords.charAt(0)
              let position_end = keyWords.length-1
              let key_end = keyWords.charAt(position_end)
              if ((key_begin=="?")&&(key_end=="?")||((keyWords)=="")) {       // search (?key?)
                words = keyWords.slice(1, position_end)
              } else if ((key_begin=="!")&&(key_end=="?")) {                  // search (!key?)
                words = "^(?!"+keyWords.slice(1, position_end)+")"
              } else if (key_begin=="!") {                                    // search (!key)
                words = "^(?!"+keyWords.slice(1)+"$)"
              } else if ((key_end) =="?") {                                   // search (key?)
                words = "^"+keyWords.slice(0, position_end)
              } else if (key_begin=="?") {                                    // search (?key)
                words = keyWords.slice(1)+"$"
              } else if (keyWords.includes("?")) {                            // search (key1/key2)
                let words_left = keyWords.slice(0, keyWords.indexOf("?"))
                let words_right = keyWords.slice(keyWords.indexOf("?")+1, keyWords.length)
                words = "^"+words_left + '.*' + words_right + "$"
              } else {                                                         // search correct
                words = "(^"+keyWords+"$)";
              }

              that.column(index).search(words, true, false, true ).draw();
            }
          }, 500))
          let searchValue = that.column(index).search()
          $('input', `.${className}.cloned`).val(searchedKeyWords[`keyword_at_${index}`]);
        });

        table.page(JSON.parse(localStorage.getItem('pageValue')) || 0).draw('page');
      },
      drawCallback: () => {
        if (this.page === 'drafts' || this.page === 'product_records' || this.page === 'product_record_suppliers') {
          let url = ""

          switch (this.page) {
            case 'product_records':
              url = Routes.update_column_product_records_path();
              break;
            case 'drafts':
              url = Routes.update_column_product_record_drafts_path();
              break;
            case 'product_record_suppliers':
              url = Routes.update_column_product_record_suppliers_path();
              break;
            }

          let columnsEditable = $('#product_records').data('columns-editable')

          let editableItemName = (item) => {
            if (item.column == 'bom_name') {
              return 'bom_id'
            } else if (item.column == 'model_group_name') {
              return 'model_group_id'
            } else if (item.column == 'assemble_group_name') {
              return 'assemble_group_id'
            } else {
              return item.column
            }
          }

          columnsEditable.forEach(item => {
            let editableOptions = {
              pk: function() {
                let $rowElement = $(this).closest('tr')
                return $rowElement.data('recordId')
              },
              showbuttons: 'bottom',
              url: url,
              name: editableItemName(item),
              ajaxOptions: {
                type: 'post',
                dataType: 'json',
              },
              success: function() {
                $(this).addClass('text-danger')
              },
              emptytext: ''
            }

            if (item.column == 'bom_name') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('boms')
            } else if (item.column == 'model_group_name') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('modelGroups')

            } else if (item.column == 'assemble_group_name') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('assembleGroups')

            } else if (item.column == 'stf2_create_target') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('stf2CreateTargetGroups')

            } else if (item.column == 'specifications_undecided') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('specificationsUndecidedGroups')

            } else if (item.column == 'bw_fw_n') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('bwFwNGroups')

            } else if (item.column == 'stf_create_target') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('stfCreateTargetGroups')

            } else if (item.column == 'plan_confirmation_flag') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('planConfirmationFlagGroups')

            } else if (item.column == 'long_term_delivery_list_target') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('longTermDeliveryListTargetGroups')

            } else if (item.column == 'development') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('developmentGroups')

            } else if (item.column == 'is_incharge') {
              editableOptions['type'] = 'select'
              editableOptions['source'] = $('body').data('isIncharges')

            } else if (item.type == 'date') {
              editableOptions['type'] = 'combodate'
              editableOptions['format'] = 'YYYY/MM/DD'
              editableOptions['viewformat'] = 'YYYY/MM/DD'
              editableOptions['template'] = 'YYYY/MM/DD'
              editableOptions['combodate'] = {
                minYear: 2020,
                maxYear: 2039,
                minuteStep: 1
              }
            } else if (item.type == 'integer') {
              editableOptions['type'] = 'number'
            } else {
              editableOptions['type'] = 'text'
            }

            const $tr = $("tr.product-record-row[is-editable=true]")
            const $td = $tr.find(`td.${item.column} span`)

            $td.editable(editableOptions);

            $td.on('shown', function(e, editable) {
              if(item.column == 'assemble_group_name') {
                $('.assemble_group_name select').select2()
              }
            });
          })
          $("#product_records thead").find('tr:first').remove();
        }
      },
    })
    $('#product_records').on( 'order.dt', function () {
      var order = table.order()
      localStorage.setItem('order', JSON.stringify(order))
    });
    // clear data column search
    $("#button_clear_search").on('click', function(){
      $('.product_records_filter input').val('');
      $('.product-records-draft-table th input, .product-records-table th input, .product-record-suppliers-table th input').val('').trigger('change');
    })
  }

  dataTableURL() {
    if (this.page === 'product_records') {
      return Routes.product_records_path()
    } else if (this.page === 'drafts') {
      return Routes.product_record_drafts_path()
    } else if (this.page === 'product_record_suppliers') {
      return Routes.product_record_suppliers_path()
    } else {
      return Routes.product_record_product_record_histories_path(productRecordID)
    }
  }

  static init(page='product_records') {
    new ProductRecord(page)
  }
}

export default ProductRecord
